export default [
  {
    title: 'API opens possibilities for the future of banking solutions',
    content:
      'Thanks to APIs, our financial chatbot and Push&Pull service can look up for customer data from our core banking system and make transactions in a blink of an eye!',
    customerName: 'Didier Mutabazi',
    customerTitle: 'Head of Digital Finance of AB Bank Rwanda',
    customerImage: '/images/a_didier.png',
  },
  {
    title: 'Our Chatbot and Loan Application work smoothly',
    content:
      'With the help of API our omni-channel in-house developed Chatbot Debby and our Field Application for loan officers are all integrated and interact smoothly with the Core Banking System 24/7.',
    customerName: 'Mordecai Omobo',
    customerTitle: 'CIO of AB Microfinance Bank Nigeria',
    customerImage: '/images/a_mordecai.png',
  },
  {
    title: 'Our digital eco-system is simply impossible without the API',
    content:
      'We are actively using API for our chatbots and payment cards. By the end of 2021 we are planning to make use of over 90% of available API methods and increase our outreach to the customers',
    customerName: 'Patrick Nirina RAKOTOMAHANDRY',
    customerTitle: 'CIO of AccèssBanque Madagascar',
    customerImage: '/images/a_patrick.png',
  },
];
