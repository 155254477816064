import React, { useState, useEffect } from 'react';
import countryData from '../data/countries.json';

const Country = (props) => {
  const { selected, handleChange, errors, name } = props;

  const mapCodeAndName = (country) => {
    nameMap[country.name.toLowerCase()] = country.code;
    codeMap[country.code.toLowerCase()] = country.name;
  };
  const [nameMap, setNameMap] = useState({});
  const [codeMap, setCodeMap] = useState({});
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    countryData.forEach(mapCodeAndName);
    countryData.sort((a, b) => {
        let x = a.name;
        let y = b.name;
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      })
    setCountries(countryData);
  }, [countries]);

  return (
    <>
      <select name={name} onChange={handleChange}>
        {countries.map((country, index) => {
          return (
            <option
              key={index}
              value={country.code}
            >
              {country.name}
            </option>
          );
        })}
      </select>
      {errors.countryCode && <p className="error">{errors.countryCode}</p>}
    </>
  );
};

export default Country;
