import React from 'react';
import PageTransition from 'gatsby-plugin-page-transitions';
import StatsBox from '../components/StatsBox';
import Transition from 'react-transition-group/Transition';

const PageTransitionComponent = (props) => {
  const {
    defaultStyle,
    transitionStyles,
    transitionTime,
    isScrollValueMoreThanStateContentHeight,
    primaryText,
    secondaryText,
  } = props;
  return (
    <PageTransition transitionTime={transitionTime}>
      <Transition in={isScrollValueMoreThanStateContentHeight} timeout={transitionTime}>
        {(state) => {
          const defaultStyleDiv =
            isScrollValueMoreThanStateContentHeight && state
              ? defaultStyle[state]
              : defaultStyle['entered'];
          return (
            <div
              style={{
                transition: `left ${transitionTime} cubic-bezier(0.47, 0, 0.75, 0.72)`,
                position: 'absolute',
                width: '100%',
                ...defaultStyleDiv,
                ...transitionStyles[state],
              }}
            >
              <StatsBox primaryText={primaryText} secondaryText={secondaryText} />
            </div>
          );
        }}
      </Transition>
    </PageTransition>
  );
};

export default PageTransitionComponent;
