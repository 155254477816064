import * as React from 'react';

function Chatbot(props) {
  return (
    <svg data-name="Layer 1" width={868.98} height={474.67} {...props} viewBox="0 0 1100 600">
      <path
        d="M47.46 432.283c-18.518 15.79-22.362 41.687-22.362 41.687s26.18.297 44.697-15.493 22.36-41.687 22.36-41.687-26.178-.297-44.696 15.493z"
        fill="#f2f2f2"
      />
      <path
        d="M56.016 438.565C48.128 461.586 25.45 474.67 25.45 474.67s-9.889-24.241-2.001-47.263 30.565-36.105 30.565-36.105 9.889 24.241 2 47.263z"
        fill="#f2f2f2"
      />
      <path
        d="M249.3 97.96c-4.189-5.429-10.11-10.452-10.485-17.151-.344-6.145 4.237-12.245 2.305-18.124-1.486-4.52-6.387-7.247-11.177-8.69a43.44 43.44 0 00-27.915 1.074l.485-.342c-5.452-1.942-11.825.063-16.115 3.776-4.29 3.714-6.84 8.86-8.817 14.02-1.979 5.157-3.512 10.504-6.26 15.34-2.75 4.836-6.958 9.233-12.51 10.905 1.859 3.708 7.67 4.98 11.078 2.425a14.014 14.014 0 002.33-2.41l6.82-8.292c2.34 4.16-1.155 9.901 1.72 13.752 1.482 1.986 4.19 2.74 6.704 3.281a121.277 121.277 0 0059.332-2.2c1.55-.457 3.26-1.086 3.903-2.49.743-1.62-.296-3.446-1.397-4.874z"
        fill="#2f2e41"
      />
      <path
        d="M305.028 119.147a10.527 10.527 0 00-.239 1.64l-42.957 24.782-10.442-6.01-11.131 14.572 17.45 12.437a8 8 0 009.599-.234l44.296-34.946a10.497 10.497 0 10-6.576-12.24z"
        fill="#ffb7b7"
      />
      <path
        d="M260.395 146.743l-13.883 17.994a4.5 4.5 0 01-6.804.373l-15.718-16.322a12.497 12.497 0 0115.327-19.744l19.713 11.023a4.5 4.5 0 011.366 6.676z"
        fill="#04a3fc"
      />
      <path fill="#ffb7b7" d="M241.288 460.267l12.26-.001 5.832-47.288-18.094.001.002 47.288z" />
      <path
        d="M238.16 456.264l24.145-.001a15.387 15.387 0 0115.387 15.386v.5l-39.53.002z"
        fill="#2f2e41"
      />
      <path fill="#ffb7b7" d="M157.288 460.267l12.26-.001 5.832-47.288-18.094.001.002 47.288z" />
      <path
        d="M154.16 456.264l24.145-.001a15.387 15.387 0 0115.387 15.386v.5l-39.53.002z"
        fill="#2f2e41"
      />
      <circle
        cx={378.374}
        cy={301.628}
        r={21.188}
        transform="rotate(-83.736 176.975 287.632)"
        fill="#ffb7b7"
      />
      <path
        d="M225.261 120.726l12.483 6.184s13.946 24.237 5.946 47.237c-3.884 11.167 7.054 46.763 7.054 46.763s16.946 161.237 17 211c-21.054 3.237-41.054 7.237-42.054-2.763S213.744 300.91 213.744 300.91 198.69 432.147 186.69 434.147s-36-1-36-1 19.054-192.237 32.054-204.237l2-10-.054-89.763L199.668 122z"
        fill="#2f2e41"
      />
      <path
        d="M180.608 256.997a10.743 10.743 0 003.291-16.14l14.517-96.763-18.966-.91-11.316 96.361a10.8 10.8 0 0012.474 17.452z"
        fill="#ffb7b7"
      />
      <path
        d="M198.004 168.34l-22.682-1.433a4.5 4.5 0 01-4.118-5.43l4.726-22.16a12.497 12.497 0 0124.94 1.649l1.902 22.505a4.5 4.5 0 01-4.768 4.868z"
        fill="#04a3fc"
      />
      <path
        d="M824.886 76.584H323.292a13.806 13.806 0 00-13.806 13.806V369.87a13.806 13.806 0 0013.806 13.806h501.594a13.806 13.806 0 0013.806-13.806V90.39a13.806 13.806 0 00-13.806-13.806z"
        fill="#3f3d56"
      />
      <path fill="#fff" d="M327.996 100.983h492.186v277.644H327.996z" />
      <circle cx={573.668} cy={88.363} r={5.048} fill="#fff" />
      <path
        fill="#f2f2f2"
        style={{
          isolation: 'isolate',
        }}
        d="M679.425 378.627H327.996V100.983l351.429 277.644z"
      />
      <path
        d="M852.511 373.579h-48.034v-3.461a.686.686 0 00-.686-.686h-16.469a.686.686 0 00-.686.686v3.46h-10.293v-3.46a.686.686 0 00-.686-.686h-16.47a.686.686 0 00-.685.686v3.46h-10.293v-3.46a.686.686 0 00-.687-.686h-16.468a.686.686 0 00-.687.686v3.46h-10.293v-3.46a.686.686 0 00-.686-.686H702.92a.686.686 0 00-.686.686v3.46H691.94v-3.46a.686.686 0 00-.686-.686h-16.47a.686.686 0 00-.685.686v3.46h-10.293v-3.46a.686.686 0 00-.687-.686h-16.468a.686.686 0 00-.687.686v3.46h-10.293v-3.46a.686.686 0 00-.686-.686H505.98a.686.686 0 00-.686.686v3.46H495v-3.46a.686.686 0 00-.686-.686h-16.47a.686.686 0 00-.685.686v3.46h-10.293v-3.46a.686.686 0 00-.687-.686H449.71a.686.686 0 00-.686.686v3.46h-10.293v-3.46a.686.686 0 00-.686-.686h-16.469a.686.686 0 00-.686.686v3.46h-10.293v-3.46a.686.686 0 00-.686-.686h-16.47a.686.686 0 00-.685.686v3.46h-10.293v-3.46a.686.686 0 00-.687-.686h-16.469a.686.686 0 00-.686.686v3.46h-10.293v-3.46a.686.686 0 00-.686-.686h-16.469a.686.686 0 00-.686.686v3.46h-31.565a16.469 16.469 0 00-16.47 16.47v7.446a16.469 16.469 0 0016.47 16.47h547.59a16.469 16.469 0 0016.468-16.47v-7.447a16.469 16.469 0 00-16.469-16.468zM583.557 342.22a12.09 12.09 0 01-.554 1.144 14.627 14.627 0 01-4.433 4.987 14.564 14.564 0 01-22.946-11.91v-2.35h1.994a50.942 50.942 0 0025.939 8.13zM612.02 334.778v1.663a14.56 14.56 0 01-27.344 6.97 12.16 12.16 0 01-.577-1.173c.426.012.846.018 1.272.018a51.018 51.018 0 0026.65-7.478zM631.642 158.99c-.438-.063-.88-.104-1.324-.133a48.982 48.982 0 01.974 36.577c.443-.053.88-.123 1.318-.204a18.374 18.374 0 00-.968-36.24zM540.424 158.857c-.444.029-.887.07-1.325.134a18.374 18.374 0 00-.968 36.239c.438.081.875.151 1.318.204a48.986 48.986 0 01.975-36.577z"
        fill="#3f3d56"
      />
      <path
        d="M631.642 158.99a50.156 50.156 0 103.89 19.366 50.228 50.228 0 00-3.89-19.365zm-.35 36.444a48.982 48.982 0 113.074-17.078 49.067 49.067 0 01-3.074 17.078z"
        fill="#3f3d56"
      />
      <path
        d="M611.035 166.982h-51.328a10.207 10.207 0 000 20.414h51.328a10.207 10.207 0 000-20.414zM631.292 265.52q-.28-.516-.578-1.015c-.005-.011-.011-.017-.017-.029a52.474 52.474 0 10-47.694 78.888c.554.03 1.113.047 1.673.047.234.011.461.011.695.011a52.108 52.108 0 0026.65-7.285 52.843 52.843 0 0020.38-21.902c.005-.011.01-.017.017-.029.175-.35.344-.706.513-1.067a52.42 52.42 0 00-1.639-47.619zm.502 47.298q-.254.543-.525 1.067a51.686 51.686 0 01-19.248 20.893 51.018 51.018 0 01-26.65 7.478c-.426 0-.846-.006-1.272-.018q-.271 0-.542-.017a51.326 51.326 0 1145.973-77.418c.006.011.012.017.018.029.204.332.402.67.59 1.01a51.263 51.263 0 011.656 46.976z"
        fill="#3f3d56"
      />
      <path
        d="M625.716 267.072a6.067 6.067 0 00-3.5-1.126h-22.94a6.025 6.025 0 100 12.05h22.94a5.959 5.959 0 001.22-.122 6.62 6.62 0 001.166-.367 6.023 6.023 0 001.114-10.435z"
        fill="#3f3d56"
      />
      <circle cx={600.803} cy={271.971} r={3.059} fill="#f1f1f1" />
      <circle cx={610.745} cy={271.971} r={3.059} fill="#f1f1f1" />
      <circle cx={620.686} cy={271.971} r={3.059} fill="#04a3fc" />
      <path
        d="M735.897 171.153c-7.955 4.771-22.128 10.749-36.611 4.606l-9.363-3.972 18.762-21.031a89.602 89.602 0 1127.212 20.397z"
        fill="#fff"
      />
      <path
        d="M735.946 172.287c-7.644 4.488-22.205 10.69-37.05 4.392l-10.648-4.516 19.095-21.405a90.602 90.602 0 11128.58 6.915 90.198 90.198 0 01-99.977 14.614zm104.74-141.25a88.602 88.602 0 10-131.26 119.047l.604.667-18.431 20.66 8.077 3.427c14.338 6.082 28.525-.236 35.707-4.542l.464-.28.487.238A88.602 88.602 0 00840.686 31.037z"
        fill="#04a3fc"
      />
      <path
        d="M850.699 90.002A82.46 82.46 0 00775.53 7.87a83.47 83.47 0 017.288-.324 82.456 82.456 0 010 164.913c-2.453 0-4.878-.116-7.276-.326a82.46 82.46 0 0075.157-82.13z"
        opacity={0.1}
        style={{
          isolation: 'isolate',
        }}
      />
      <path
        fill="#04a3fc"
        d="M727.772 73.559h58.92v5.472h-58.92zM727.772 92.487h102.692v5.472H727.772zM727.772 111.415h102.591v5.472H727.772z"
      />
      <path d="M382 473.194H1a1 1 0 010-2h381a1 1 0 010 2z" fill="#cbcbcb" />
      <path
        d="M235.07 75.19c-.825-3.745-3.507-6.848-6.596-9.12a27.017 27.017 0 00-34.537 2.1 17.65 17.65 0 00-5.334 8.549 10.486 10.486 0 002.234 9.548l.424.265c-.384-1.628.88-3.255 2.391-3.973a19.11 19.11 0 014.879-1.094 17.935 17.935 0 0012.157-8.578 20.254 20.254 0 0011.607 12.096c2.8 1.095 10.492 2.601 13.158 1.211 3.4-1.773.443-7.258-.383-11.004z"
        fill="#2f2e41"
      />
    </svg>
  );
}

const ImageChatbot = React.memo(Chatbot);
export default ImageChatbot;
