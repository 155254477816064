import React, { useState, useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { toast } from 'react-toastify';
import Airtable from 'airtable';
import NavButton from '../components/NavButton';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import ImageChatbot from '../svg/Chatbot';
import ImageProcess from '../svg/Process';
import ImageAnalytics from '../svg/Analytics';
import '../css/style.css';
import PageTransitionComponent from '../components/PageTransition';
import Country from '../components/Country';

const Index = () => {
  const [defaultStyle, setDefaultStyle] = useState({});
  const [transitionStyles, setTransitionStyles] = useState({});
  const [
    isScrollValueMoreThanStateFirstContentHeight,
    setIsScrollValueMoreThanStateFirstContentHeight,
  ] = useState(false);
  const [
    isScrollValueMoreThanStateSecondContentHeight,
    setIsScrollValueMoreThanStateSecondContentHeight,
  ] = useState(false);
  const [
    isScrollValueMoreThanStateThirdContentHeight,
    setIsScrollValueMoreThanStateThirdContentHeight,
  ] = useState(false);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [errors, setErrors] = useState({
    email: '',
    name: '',
    password: '',
    countryCode: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateForm(errors)) {
        const userData = {
          email: email,
          name: name,
          password: password,
          Status: 'Applied',
          'country code': countryCode,
        };
        const base = new Airtable({ apiKey: process.env.GATSBY_AIRTABLE_API_KEY }).base(
          process.env.GATSBY_AIRTABLE_BASE_ID
        );
        base('Users')
          .select({
            filterByFormula: `({email} = '${email}')`,
            maxRecords: 1,
            view: 'Grid view',
          })
          .firstPage(function (err, records) {
            if (err) {
              toast.error(`Airtable Error: ${err.message}`);
              return;
            }
            if (records.length > 0) {
              toast.error(`User exists with email: ${email}, Please try different email`);
              return false;
            } else {
              base('Users').create(
                [
                  {
                    fields: userData,
                  },
                ],
                function (err, records) {
                  if (err) {
                    toast.error(`Airtable Error: ${err.message}`);
                    return;
                  }
                }
              );
              toast.success('You are registered successfully!');
            }
            setEmail('');
            setName('');
            setPassword('');
            setCountryCode('');
          });
      } else {
        toast.error('Invalid Form, Please enter proper values');
      }
    } catch (err) {
      toast.error(`Network Error: ${err.message}`);
    }
  };

  const validateForm = (formErrors) => {
    let valid = true;
    if (!email) {
      valid = false;
      errors.email = 'Please enter email';
    }
    if (!name) {
      valid = false;
      errors.name = 'Please enter your name';
    }
    if (!password) {
      valid = false;
      errors.password = 'Please enter your password';
    }
    if (!countryCode) {
      valid = false;
      errors.countryCode = 'Please select the country';
    }
    setErrors({
      ...errors,
      errors,
    });
    Object.values(formErrors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const validEmailRegex = RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    switch (name) {
      case 'email':
        errors.email = value && validEmailRegex.test(value) ? '' : 'Email is not valid!';
        setEmail(value);
        break;
      case 'name':
        errors.name = '';
        setName(value);
        break;
        case 'password':
          errors.password = '';
          setPassword(value);
          break;
        case 'countryCode':
        errors.countryCode = '';
        setCountryCode(value);
        break;
      default:
        break;
    }

    setErrors({
      ...errors,
      errors,
    });
  };

  useEffect(() => {
    setDefaultStyle({
      entering: {
        left: '100%',
      },
      entered: {
        left: '50%',
      },
      exiting: {
        left: '0%',
      },
      exited: {
        transition: 'left 500ms cubic-bezier(0.47, 0, 0.75, 0.72)',
        left: '100%',
      },
    });

    setTransitionStyles({
      entering: { left: '0%' },
      entered: { left: '0%' },
      exiting: { left: '100%' },
    });
    const handleScroll = () => {
      const topOffset = document.getElementById('stats').offsetTop;
      if (window.pageYOffset > topOffset - 500) {
        setIsScrollValueMoreThanStateFirstContentHeight(true);
        setTimeout(function () {
          setIsScrollValueMoreThanStateSecondContentHeight(true);
        }, 150);
        setTimeout(function () {
          setIsScrollValueMoreThanStateThirdContentHeight(true);
        }, 300);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [
    isScrollValueMoreThanStateFirstContentHeight,
    isScrollValueMoreThanStateSecondContentHeight,
    isScrollValueMoreThanStateThirdContentHeight,
  ]);

  return (
    <Layout>
      <section className="pt-20 md:pt-40">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">Banking API</h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              We value diversity and facilitate integrations!
            </p>
            <p className="mt-8 md:mt-12">
              <NavButton size="lg">Register</NavButton>
            </p>
            <p className="mt-4 text-gray-600">Integrate your systems - connect your worlds</p>
          </div>
          <div className="lg:w-1/2 fade-in">
            <HeroImage />
          </div>
        </div>
      </section>
      <section id="features" className="py-20 lg:pb-40 lg:pt-48">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">Main Features</h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">
                  Enjoy the speed of deployment with Kubernetes
                </p>
                <p className="mt-4">
                  Build with K8s and assure scaling and immutability of your applications while
                  eliminating many of the manual processes involved in deploying your containerized
                  application.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">Over 130 methods to meet your requirements</p>
                <p className="mt-4">
                  Build your applications easy and in no time with already provided methods for
                  customer data, loans, deposits, transactions or accounts
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">
                  Includes Apache Camel as the Integration Layer
                </p>
                <p className="mt-4">
                  Our API comes with Integration layer that helps you integrate various systems,
                  allowing the introduction of custom protocols and data types while being
                  extensible.
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <SplitSection
        id="services"
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Automate business process and accelerate your digital transformation
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              The core strength of our Banking API is ensuring secure and conflict-free
              communication between distinct end-to-end applications of your legacy systems and the
              new ones. Instead of having to replace them for not being of the same technology or
              functional use case, experience seamless connectivity with our API. Benefits for your
              company will range from improved efficiency, productivity and cost control, through
              flexibility to business systems in case of new adoption, to the similar digital
              experiences across dissimilar systems. Additionally, it will stabilize your software
              ecosystem and provide smooth future integrations with new systems.
            </p>
          </div>
        }
        secondarySlot={
          <Parallax x={[100, -20]}>
            <ImageProcess />
          </Parallax>
        }
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">
              When conversational banking is your business, make use of our API
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Discover and understand what your clients really want with the Chabot inside your
              messaging applications. Easily integrate your Chatbot into any back-end system,
              including CRM, scheduling tools, order and inventory management systems, payment
              platforms, and more. Take in the request, extract the intent or meaning of the
              message, and deliver the response all that through our API. Automate conversations and
              enjoy the simplicity of speech-to-text (STT) and text-to-speech (TTS)based
              communications.
            </p>
          </div>
        }
        secondarySlot={
          <Parallax x={[-100, 0]} y={[20, 0]} strength={300}>
            <ImageChatbot />
          </Parallax>
        }
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Support your most important decisions with real time and accurate data
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              With our API data is always delivered from its primary source, hence reliable and
              error free. Standardize your data and ensure consistency, while at the same time
              reducing the risk of errors in interpretation. Improve the speed and efficiency of
              accessing different historical data sets, evaluate outcomes of various initiatives in
              the past and guide the business and marketing strategies with ease. Improve overall
              accuracy and make decision process easier for the corporate leaders.
            </p>
          </div>
        }
        secondarySlot={
          <Parallax x={[30, -10]}>
            <ImageAnalytics />
          </Parallax>
        }
      />
      <section id="stats" className="py-20 lg:pt-32">
        <div className="container mx-auto text-center">
          <LabelText className="text-gray-600">Our customers get results</LabelText>
          <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
            <>
              <div className="w-full sm:w-1/3" style={{ position: 'relative' }}>
                <PageTransitionComponent
                  defaultStyle={defaultStyle}
                  transitionStyles={transitionStyles}
                  transitionTime={500}
                  isScrollValueMoreThanStateContentHeight={
                    isScrollValueMoreThanStateFirstContentHeight
                  }
                  primaryText={'73%'}
                  secondaryText={'Transactions outside branches'}
                />
              </div>
              <div className="w-full sm:w-1/3" style={{ position: 'relative' }}>
                <PageTransitionComponent
                  defaultStyle={defaultStyle}
                  transitionStyles={transitionStyles}
                  transitionTime={500}
                  isScrollValueMoreThanStateContentHeight={
                    isScrollValueMoreThanStateSecondContentHeight
                  }
                  primaryText={'24/7'}
                  secondaryText={'Service availability'}
                />
              </div>
              <div className="w-full sm:w-1/3" style={{ position: 'relative' }}>
                <PageTransitionComponent
                  defaultStyle={defaultStyle}
                  transitionStyles={transitionStyles}
                  transitionTime={500}
                  isScrollValueMoreThanStateContentHeight={
                    isScrollValueMoreThanStateThirdContentHeight
                  }
                  primaryText={'-10%'}
                  secondaryText={'Loans overdue'}
                />
              </div>
            </>
          </div>
        </div>
      </section>
      <section id="testimonials" className="py-20 lg:pt-32">
        <div className="container mx-auto">
          <LabelText className="mb-8 text-gray-600 text-center">
            What customers are saying
          </LabelText>
          <div className="flex flex-col md:flex-row md:-mx-3">
            {customerData.map((customer) => (
              <div key={customer.customerName} className="flex-1 px-3">
                <CustomerCard customer={customer} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="register" className="container mx-auto my-20 py-24 bg-gray-200 lg:pt-32 rounded-lg text-center">
        <h3 className="text-4xl font-semibold">Register for free and start developing your own solutions!</h3>
        <p className="mt-8 text-xl font-light">
          By clicking “Register” button, you agree to the storing of your personal data in our database for the sole purpose of granting you access to the Banking API sanbox. You are informed that you can request us to delete your personal data anytime.
        </p>
        <div className="flex flex-col md:flex-row md:-mx-12 mt-5">
            <form onSubmit={handleSubmit} noValidate className="register-form">
              <div className="form-group">
                <label className="form-control">Email</label>
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  autoComplete="off"
                  placeholder="Email"
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="form-group">
                <label className="form-control">Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  autoComplete="off"
                  placeholder="Name"
                />
                {errors.name && <p className="error">{errors.name}</p>}
              </div>
              <div className="form-group">
                <label className="form-control">Password</label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  autoComplete="off"
                  placeholder="Password"
                />
                {errors.password && <p className="error">{errors.password}</p>}
              </div>
              <div className="form-group">
                <label className="form-control">Country</label>
                <Country
                  selected={countryCode}
                  handleChange={handleChange}
                  name={'countryCode'}
                  errors={errors}
                />
              </div>
              <div className="mt-8 text-center">
                <NavButton size="xl" type={'submit'}>Register</NavButton>
              </div>
            </form>
          </div>

      </section>
    </Layout>
  );
};

export default Index;
